
.addArticle{
  padding: 144px 64px;
}

.newArticleTitleInput{
  margin-bottom: 22px;
  width: calc(100% - 24px);
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 12px;
  font-size: 18px;
}
.imgInput{
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 12px;
  width: calc(100% - 24px);
  margin-bottom: 22px;
}
.imgPreview{
  width: 100%;
  height: 300px;
  object-fit: contain;
}
.uploadImgBtn{
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 8px;
  border-radius: 8px;
  background-color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.submitBtnContainer{
  margin-top: 34px;
  display: flex;
  justify-content: flex-end;
}
.submitBtn{
  padding: 12px 22px;
  background-color: #fff;
  color: #176AE5;
  border-radius: 30px;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  width: max-content;
  border: 1px solid #176AE5;
}
.submitBtn:hover{
  text-decoration: underline;
  background-color: #104ba2;
  color: #fff;
}

.articleBodyTypeWrapper{
  margin-bottom: 18px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 4px;
  display: flex;
  gap: 8px;
}

.articleBodyTypeBtn{
  border: 0;
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 8px;
  cursor: pointer;
}

.activeBodyTypeBtn{
  background-color: #176AE5;
  color: #fff;
  border-radius: 6px;
}
.htmlTextArea{
  width: calc(100% - 24px);
  border-radius: 8px;
  padding: 12px;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.25);
}