.admin{
  padding: 144px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.adminPassTitle{
  font-size: 48px;
  font-weight: 700;
  text-align: center;
}
.adminPassError{
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #AC001F;
}
.adminPassInputs{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.adminPassInput{
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  padding: 12px 18px;
  width: 30%;
  font-size: 16px; 
  margin-right: 16px;
}
.adminPassBtn{
  padding: 12px 22px;
  background-color: #fff;
  color: #176AE5;
  border-radius: 30px;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  width: max-content;
  border: 1px solid #176AE5;
  text-decoration: none;
}
.adminPassBtn:hover{
  text-decoration: underline;
  background-color: #104ba2;
  color: #fff;
}

.adminHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-bottom: 32px;
}
.adminTitle{
  font-size: 32px;
  font-weight: 700;
}

.article{
  width: 65%;
  height: 165px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 52px;
}
.articleImgContainer{
  width: 30%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
}
.articleImg{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.articleBodyContainer{
  width: 66%;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.articleDate{
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  color: #888;
}
.articleTitle{
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.15;
  margin: 0;
  margin-top: 12px;
}

.editBtn{
  padding: 6px 12px;
  background-color: #fff;
  color: #176AE5;
  border-radius: 15px;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #176AE5;
  margin-right: 12px;
  text-decoration: none;
}
.editBtn:hover{
  text-decoration: underline;
  background-color: #104ba2;
  color: #fff;
}
.deleteBtn{
  padding: 6px 12px;
  background-color: #fff;
  color: #AC001F;
  border-radius: 15px;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #AC001F;
  text-decoration: none;
}
.deleteBtn:hover{
  text-decoration: underline;
  background-color: #AC001F;
  color: #fff;
}