.App {
}

.btn1{
  padding: 12px 22px;
  background-color: #176AE5;
  color: #fff;
  border-radius: 30px;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.btn1:hover{
  text-decoration: underline;
}
.btn2{
  padding: 12px 22px;
  border: .5px solid #000;
  border-radius: 30px;
  cursor: pointer;
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.btn2:hover{
  color: #fff;
  background-color: #333333;
}

/**************************   loading spinner animation  ****************************************/
.loader,.loader:after {
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em;
}
.loader {
  font-size: 16px;
  position: absolute;
  z-index: 3;
  top: 40%;
  left: 50%;
  text-indent: -9999em;
  border-top: 0.4em solid #DFF6FF;
  border-right: 0.4em solid #DFF6FF;
  border-bottom: 0.4em solid #DFF6FF;
  border-left: 0.4em solid #1363DF;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 0.8s infinite linear;
  animation: load8 0.8s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
/************************************************************************************************/