

.articleContainer{
  padding: 144px 64px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.article{
  width: 70%;
}
.articleHead{
  display: flex;
  align-items: center;
}
.backBtn{
  cursor: pointer;
  padding: 6px;
}
.backBtn:hover{
  background-color: #176AE5;
  border-radius: 100%;
  color: #fff;
}
.date{
  color: #888;
  font-weight: 500;
  margin-left: 24px;
}
.title{
  font-size: 42px;
  font-weight: 800;
  margin: 0;
}
.imgContainer{
  width: 100%;
  max-height: 500px;
  margin: 32px 0;
}
.img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}

@media (max-width: 642px){
  .articleContainer{
    padding: 84px 24px;
  }
  .article{
    width: 100%;
  }
  .date{
    font-size: 14px;
    margin-left: 12px;
  }
  .title{
    font-size: 32px;
  }
}