
.block{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}
.firstB{
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: rgb(217,241,254); */
  /* background: linear-gradient(180deg, rgba(217,241,254,1) 80%, rgba(255,255,255,1) 100%); */
  /* background-image: url("https://lh6.googleusercontent.com/Hh_5LeNlDDjqcUl7oS1TOCecog0o2qYPLqOvegsIUB6nqCn6oQUeciXp-xAxOKBcTaEHZZ6chm1dQKAs64I0DRY=w16383"); */
  background-size: cover;
  background-repeat: no-repeat;
  
  margin-top: 0;
}
.main_title{
  text-align: center;
  width: 75%;
  font-size: 52px;
  font-weight: 700;
  color: #032D5F;
  line-height: 72px;
  margin: 0;
  padding: 0;
}
.main_subTitle{
  margin-top: 22px;
  font-size: 22px;
  line-height: 34px;
  padding: 0;
  font-weight: 300;
}

.firstB_btns{
  display: flex;
  align-items: center;
}
.main_inputContainer{
  border-radius: 30px;
  border: 1px solid #032D5F;
}
.main_input{
  border: 0;
  outline: none;
  /* text-transform: capitalize; */
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  background-color: transparent;
  font-size: 15px;
  margin-right: 8px;
  margin-left: 22px;
}
.main_inputBtn{ 
  padding: 14px 28px;
  background-color: #fff;
  color: #176AE5;
  border-radius: 30px;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  width: max-content;
  border: 1px solid #176AE5;
}
.main_inputBtn:hover{
  text-decoration: underline;
  background-color: #104ba2;
  color: #fff;
}
.firstB_orSeparator{
  color: #888;
  margin: 0 16px;
}
.mainBookBtn{
  padding: 15px 28px;
  background-color: #176AE5;
  color: #fff;
  border-radius: 30px;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  width: max-content;
  text-decoration: none;
}
.mainBookBtn:hover{
  background-color: #104ba2;
  text-decoration: underline;
}

.rowView{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px;
}

.mainFeatures{
  width: calc(100% - 140px);
  padding: 28px 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #fff; */
}
.mainFeature{
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  /* border: 1px solid #000; */
  border-radius: 15px;
  transition: all 0.3s;
  box-shadow: none;
}
.mainFeature:hover{
  box-shadow: 0 4px 18px rgba(0,0,0,0.15);
}
.mainFeatureImg{
  width: 100%;
  height: auto;
  object-fit: contain;
  mix-blend-mode: multiply;
}
.mainFeatureDesc{
  font-size: 18px;
  font-weight: 300;
  margin-top: 18px;
}
.mainFeatureIso{
  font-weight: 500;
  margin-top: 8px;
}

.msg{
  margin: 64px 0;
  width: 84%;
  text-align: center;
  padding: 32px 16px;
  font-style: italic;
  font-size: 16px;
  border-width: 1px 0;
  border-style: solid;
  line-height: 1.6rem;
  display: flex;
  flex-direction: column;
  position: relative;
}
.authName{
  margin-top: 16px;
  font-weight: bold;
  font-size: 12px;
  font-style: normal;
}
.authInfo{
  font-size: 12px;
  font-style: normal;
  color: #888;
}
.authImg{
  position: absolute;
  bottom: -30px;
  right: 30px;
  width: 70px;
  height: 70px;
  border-radius: 70px;
  background-color: #888;
}

.preview{
  padding-left: 44px;
  padding-right: 70px;
  display: flex;
  justify-content: space-between;
  margin: 64px 16px;
  align-items: center;
}
.prev_categories{
  list-style-type: none;
  padding: 0;
  width: 20%;
}
.prev_cat {
  padding: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 10px;
  color: #888;
  margin-bottom: 12px;
}
.prev_cat:hover{
  color: #2F2F30;
}
.selctedCat{
  background-color: #D9F1FE;
  box-shadow: 0 3px 6px rgba(0,0,0,0.10);
  color: #2F2F30;
}
.pc-phoneImg{
  position: relative;
  width: 80%;
}
.pcMockup{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.phoneImg{
  position: absolute;
  bottom: -25px;
  right: -25px;
  width: 200px;
  height: 400px;
  z-index: 20;
}
.phoneMockup{
  width: 200px;
  height: 400px;
  object-fit: contain;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 30;
}

.selectPhonePrev{
  display: none;
}

.pc_content{
  width: 83%;
  height: 87.5%;
  background-color: #F2F2F2;
  position: absolute;
  top: 5.5%;
  right: 8.5%;
  z-index: 10;
  border-radius: 5px;
}
.pc_contentImg{
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 10px;
}
.phone_contentImg{
  width: 174px;
  height: 374px;
  object-fit: contain;
  position: absolute;
  bottom: 14px;
  right: 14px;
  z-index: 20;
  background-color: #F2F2F2;
  border-radius: 0 0 20px 20px;
}
.prev_seeMore{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0;
  cursor: pointer;
}

.title{
  font-size: 32px;
  font-weight: 700;
  margin-top: 32px;
  color: #032D5F;
  margin-bottom: 8px;
}
.subTitle{
  margin-top: 16px;
  font-weight: 400;
}

.mainFeature_card{
  width: calc(90%);
  padding: 16px 22px;
  border-radius: 10px;
  border: 1px solid rgba(0,0,0,0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  cursor: default;
}
.features{
  width: 100%;
  display: grid;
  grid-template-columns: 30% 30% 30%; 
  grid-template-rows: max-content;
  gap: 22px;
  justify-content: center;
  margin-top: 22px;
  margin-bottom: 60px;
}
.feature_card{
  width: calc(100% - 44px);
  padding: 16px 22px;
  border-radius: 10px;
  border: 1px solid rgba(0,0,0,0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  cursor: default;
}
.mainFeature_card:hover ,.feature_card:hover{
  box-shadow: 0 4px 18px rgba(0,0,0,0.15);
  border-color: transparent;
}

.featureTitle{
  font-weight: bold;
  font-size: 18px;
  font-style: normal;
  color: #032D5F;
  margin: 0;
}
.featureText{
  font-style: normal;
  margin-top: 16px;
  line-height: 24px;
}

.advantageCard{
  width: calc(90%);
  padding: 16px 22px;
  border-radius: 10px;
  box-shadow: 0 4px 18px rgba(0,0,0,0.15);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 62px;
  position: relative;
}
.advantageCardTitle{
  position: absolute;
  width: max-content;
  padding: 0 22px;
  height: 60px;
  border-radius: 80px;
  background-color: #fff;
  top: -30px;
  box-shadow: 0 4px 18px rgba(0,0,0,0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #032D5F;
  font-weight: 700;
  font-size: 24px;
}
.advantageCardTitle_left{
  left: 22px;
}
.advantageCardTitle_right{
  right: 22px;
}
.advantageText{
  font-style: normal;
  margin-top: 34px;
  line-height: 24px;
}

@media (max-width: 642px){
  .main_title{
    font-size: 34px;
    line-height: 48px;
    width: 90%;
    text-align: start;
  }
  .main_subTitle{
    width: 90%;
  }
  .main_input{
    display: none;
  }

  .firstB_btns{
    flex-direction: column;
    gap: 8px;
  }
  .main_inputBtn{
    width: 60vw;
    padding: 14px 0;
    text-align: center;
  } 
  .mainBookBtn{
    width: 60vw;
    padding: 15px 0;
    text-align: center;
  }

  .mainFeatures{
    flex-direction: column;
    gap: 48px;
  }
  .mainFeature{
    width: 100%;
    text-align: center;
  }

  .preview{
    padding: 0;
    margin-top: 22px;
  }
  .pc-phoneImg{
    width: 100%;
  }
  .prev_categories, .pcMockup, .pc_content{
    display: none;
  }
  .selectPhonePrev{
    background-color: #D9F1FE;
    padding: 12px;
    border-radius: 15px;
    min-width: 200px;
    margin-bottom: 22px;
    display: block;
  }
  .phoneImg{
    position: relative;
    right: 0;
    top: 0;
  }

  .title{
    margin-top: 0;
    text-align: center;
  }
  .subTitle{
    width: 90%;
    text-align: center;
    line-height: 24px;
  }
  .mainFeature_card, .feature_card{
    width: calc(90% - 44px);
  }
  .features{
    grid-template-columns: 100%;
    padding-left: 10%;
  }

  .advantageCard{
    width: calc(90% - 44px);
  }
  .advantageCardTitle{
    font-size: 18px;
    height: 46px;
    top: -23px;
    padding: 0 16px;
  }
  .advantageCardTitle_right, .advantageCardTitle_left{
    left: 18px;
  }
}


.flip {
  width: 200px;
  height: 248px;   
  display: flex;
  align-items: center;
  perspective: 600px; 
}
.flip-content {
  width: 100%;
  height: 200px;
  transition: transform 0.4s;
  transform-style: preserve-3d;
}
.flip:hover .flip-content {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}
.flip-front, .flip-back {
  position: absolute;
  height: 100%;
  width: 176px;
  backface-visibility: hidden;  
  color: #000;  
  margin-right: 12px;
  margin-left: 12px;
}
.flip-back {
  transform: rotateY(180deg);
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 15px;
}
.frontImgContainer{
  height: 200px;
  width: 176px;
}
.frontImg{
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: grayscale(1);
}
.backContainer{
  padding: 12px;
}
.backImgContainer{
  height: 80px;
  width: 80px;
}
.backImg{
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
  /* background-color: red; */
  display: flex;
  justify-content: center;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}


.advantageCardBlock{
  display: flex;
  padding: 0 34px;
}
.advantageCard_V2{
  height: 60vh;
  width: 50vw;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.advantageCardImgContainer{
  position: sticky;
  width: 50vw;
  height: 60vh;
  /* background-color: red; */
  top: 120px;
  right: 34px;
}
.advantageCardImgBox{
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden;
  height: 0%;
}
.advantageCardImg{
  height: 100%;
  width: 100%;
  object-fit: contain;
  mix-blend-mode: multiply;
}
.showImg{
  opacity: 1;
  visibility: visible;
  height: 100%;
}

.advantageCardTitle_V2{
  color: #032D5F;
  font-weight: 700;
  font-size: 2.3rem;
  line-height: 1.4;
  margin-bottom: 24px;
}
.advantageCardText_V2{
  color: rgb(66, 84, 102);
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.6;
  max-width: 90%;
}

.articlesBlockTitleContainer{
  position: relative;
  width: 100vw;
  text-align: center;
}
.articlesBlockTitle{
  font-size: 32px;
  font-weight: 700;
  color: #032D5F;
  margin: 0;
}
.articlesSeeAllText{
  cursor: pointer;
  color: #888;
  font-weight: 600;
  font-size: 14px;
  position: absolute;
  top: 12px;
  right: 68px;
  text-decoration: none;
}
.articlesSeeAllText:hover{
  text-decoration: underline;
}

.main_articles{
  width: 100%;
  display: grid;
  grid-template-columns: 30% 30% 30%; 
  grid-template-rows: max-content;
  gap: 22px;
  justify-content: center;
  margin-top: 22px;
  margin-bottom: 60px;
}
.main_article{
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 15px;
  max-height: 250px;
  height: max-content;
  padding: 8px;
  position: relative;
}
.main_articleTitle{
  border-radius: 10px;
  height: 126px;
  width: calc(100% - 24px);
  background: linear-gradient(0deg, rgba(0,0,0, 0.6) 25%, rgba(255,255,255,0) 100%);
  position: relative;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  padding: 12px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.6;
}
.main_articleImg{
  height: 150px;
  width: calc(100% - 16px);
  object-fit: cover;
  border-radius: 10px;
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 0;
}
.main_articleText{
  color: #888;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2; 
  -webkit-box-orient: vertical;
  height: 45px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.main_articleDate{
  color: #888;
  font-size: 12px;
  font-weight: 400;
}
.main_articleBtn{
  border: 1px solid rgba(0,0,0,0.15);
  background-color: #fff;
  border-radius: 30px;
  height: 44px;
  width: 44px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -22px;
  right: 12px;
}
.main_articleBtn:hover{
  width: 144px;
}
.main_articleBtnText{
  color: #888;
  font-size: 14px;
  font-weight: 600;
  display: none;
  transition: all 0.5s;
}
.activeMainArticleBtnText{
  display: block;
  margin-right: 12px;
}

.NotFoundPage{
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


@media (max-width: 642px){
  .advantageCardBlock{
    flex-direction: column;
  }
  .advantageCardImgContainer{
    display: none;
  }
  .advantageCard_V2{
    width: 100%;
  }
  .advantageCardTitle_V2{
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1.4;
    margin-bottom: 24px;
  }
  .advantageCardText_V2{
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.6;
    max-width: 100%;
  }
  .main_articles{
    grid-template-columns: 80%;
    gap: 32px;
  }

  .articlesSeeAllText{
    right: 12px;
  }
}