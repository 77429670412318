.nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 60px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparent;
    transition: all .2s;
    width: calc(100% - 120px);
    z-index: 50;
    box-shadow: none;
}
.nav_logoContainer{
    width: 15%;
    height: auto;
}
.nav_logo{
    width: 100%;
    height: 100%;
    mix-blend-mode: multiply;
    object-fit: contain;
}
.headerNav_btns{
    display: flex;
    list-style-type: none;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight:500;
    margin: 0;
}
.headerNav_btns li{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.navBtnText{
    text-decoration: none;
    color: #000;
    margin-right: 24px;
}
.headerNav_btns li:hover{
    text-decoration: underline;
}
.navContactUsBtn{
    margin-right: 24px;
    color: #104ba2;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    width: max-content;
    border: 0;
    background-color: transparent;
}
.navContactUsBtn:hover{
    text-decoration: underline;
}

.navBookBtn{
    padding: 12px 22px;
    background-color: #176AE5;
    color: #fff;
    border-radius: 30px;
    border: 0;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    width: max-content;
}
.navBookBtn:hover{
    background-color: #104ba2;
}

@media (max-width: 642px){
    .nav{
        width: calc(100% - 40px);
        padding: 12px 20px;
    }
    .navBtnText{
        display: none;
    }
    .navContactUsBtn{
        display: none; 
    }
    .nav_logoContainer{
        width: 50%;
    }
    .navBookBtn{
        padding: 10px 12px;
        font-size: 13px;
    }
}

/*************************  Footer  **********************/
.footer{
    background: rgb(217,241,254);
    background: linear-gradient(0deg, rgba(217,241,254,1) 80%, rgba(255,255,255,1) 100%);
    width: calc(100% - 240px);
    padding: 120px;
    color: #444444;
    display: flex;
}
.footer_logoContainer{
    width: 25%;
    height: auto;
}
.footer_logo{
    width: 100%;
    height: 100%;
    mix-blend-mode: multiply;
    object-fit: contain;
}
.RS{
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    margin-right: 16px;
    margin-top: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #444444;
}
.RS_fb:hover{
    background-color: #1776EB;
    color: #fff;
}
.RS_Linkedin:hover{
    background-color: #0963BC;
    color: #fff;
}
.contactDetails{
    margin: 0;
    list-style-type: none
}
.link{
    color: #444444;
}
.link:hover{
    color: #032D5F;
}

.msgUs{
    color: #1776EB;
    border: 1px solid #1776EB;
    text-align: center;
    padding: 12px 0;
    border-radius: 15px;
    max-width: 60%;
    cursor: pointer;
}

@media (max-width: 642px){
    .footer{
        flex-direction: column;
        width: calc(100% - 40px);
        padding: 20px;
    }
    .footer_logoContainer{
        width: 50%;
    }
    .contactDetails{
        margin-bottom: 16px;
    }
    .msgUs{
        max-width: 100%;
    }
}