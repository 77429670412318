
.modal{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 50;
}
.modalContainer{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 20;
  padding: 22px;
  width: 65%;
  height: max-content;
  max-height: 80%;
  border-radius: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-wrap: wrap;
}
.modalHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.modalTitle{
  font-weight: 600;
  font-size: 18px;
  color: #888;
}
.modal_X{
  width: 1.75rem;
  height: 1.75rem;
  color: #888;
  cursor: pointer;
}
.modal_bg{
  position: absolute;
  z-index: 10;
  top: 0;
  background-color: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100vh;
}

.modalContent{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.fields{
  width: calc(48% - 32px);
  height: 100%;
  background-color: #F4F4F4;
  padding: 16px;
  border-radius: 15px;
}
.field{
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.nameField{
  display: grid;
  grid-template-columns: calc(50% - 4px) calc(50% - 4px);
  gap: 8px;
}
.fieldLabel{
  font-size: 12px;
  line-height: 1.25rem;
  font-weight: 600;
  color: rgb(107 114 128);
  margin-left: 12px;
}
.fieldInput{
  border-radius: 12px;
  margin-bottom: 22px;
  font-size: 15px;
  padding: 10px 12px;
  border: 0.5px solid #888 ;
  max-width: calc(100% - 24px);
  min-height: 20px;
}

.contactBtn{
  padding: 12px 22px;
  background-color: #176AE5;
  color: #fff;
  border-radius: 30px;
  border: 0;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.contactBtn:hover{
  text-decoration: underline;
}

.info{
  width: 48%;
  height: 100%;
  /* background-color: green; */
}
.infoTitle{
  font-size: 32px;
  font-weight: 300;
  margin: 0;
  margin-bottom: 32px;
}
.msgSendedTitle{
  font-size: 32px;
  font-weight: 300;
  margin: 0;
  margin-bottom: 32px;
}
.infoSubTitle{
  font-size: 16px;
  line-height: 22px;
}
.infoText{
  font-size: 16px;
  line-height: 22px;
  width: calc(100% - 36px);
}
.infoBelowTitle{
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
  margin-bottom: 0;
}
.mobileTitle{
  display: none;
  font-size: 26px;
  font-weight: 300;
  margin: 0;
  margin-bottom: 22px;
}

.errorView{
  width: 90%;
  margin-bottom: 16px;
  text-align: center;
}
.errorMsg{
  color: #E94461;
  font-size: 14px;
  font-weight: 600;
}


@media (max-width: 642px){
  .modalContainer{
    width: calc(100% - 34px);
    height: max-content;
    max-height: 90%;
    padding: 12px;
  }
  .modalContent{
    flex-direction: column;
  }
  .fields{
    width: calc(100% - 32px);
  }
  .info{
    width: 100%;
  }
  .mobileTitle{
    display: block;
  }
  .infoTitle{
    display: none;
  }
}