
.articles{
  padding: 144px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.article{
  width: 65%;
  height: 165px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 52px;
}
.articleImgContainer{
  width: 30%;
  height: 100%;
}
.articleImg{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.articleBodyContainer{
  width: 66%;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.articleTitle{
  font-size: 1.9rem;
  font-weight: 600;
  line-height: 1.15;
}
.articleFooter{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}
.articleBtn{
  border: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.articleBtnText{
  color: #888;
  font-weight: 500;
  font-size: 14px;
  margin-right: 8px;
}
.articleBtn:hover{
  text-decoration: underline;
}

@media (max-width: 642px){
  .article{
    width: 95%;
    margin-bottom: 12px;
  }
  .articleImgContainer{
    width: 40%;
  }
  .articleBodyContainer{
    width: 56%;
  }
  .articleTitle{
    font-size: 1.3rem;
  }
  .articleBtnText{
    font-size: 13px;
    margin-right: 4px;
  }
}